<template>
    <div>
        <h2>{{ $t("payment.payment") }}</h2>
        <div class="box">
            <DateRangeV2
                :start.sync="start_date"
                :end.sync="end_date"
                @submit="loadPayments"
                :dateSelect="true"
                :periodToShow="['personalise','jour','mois','mois_dernier']"
            />

            <CustomTable
                ref="table_payment"
                id_table="payment"
                :items="payments"
                :busy.sync="table_busy"
                primaryKey="payment_id"
                :hrefsRoutes="config_table_hrefs"
                :hide_if_empty="true"
                :rawColumns="rawColumnNames"
				:externSlotColumns="extern_slot_columns"
            >
                <template v-slot:[`custom-slot-cell(payment_exported)`]="{ data }">
					<font-awesome-icon class="text-success" v-if="data.payment_exported" :icon="['fal', 'check-circle']" />
					<font-awesome-icon class="text-danger" v-else :icon="['fal', 'times-circle']" />
				</template>
				<template v-slot:custom-slot-cell(check_deposit_formatted)="{ data }">
					<div class="d-flex align-items-center">
						<a href="#" @click.prevent="print({checkdeposit_ids: data.check_deposit.checkdeposit_id.toString()})">{{ data.check_deposit_formatted }}</a>
					</div>
				</template>
                <template v-slot:custom-slot-cell(formatted_invoices)="{ data }">
					<p v-for="invoice in data.invoices" :key="invoice.invoice_id">
                        <a href="" class="" @click.prevent="quickPreview(invoice.invoice_id)">{{invoice.invoice_num}}</a> ({{ $t('invoice.invoice_balance') }} : {{ invoice.invoice_balance }})
                    </p>
				</template>
                <template v-slot:custom-slot-cell(contracts)="{ data }">
                    <span v-for="(contract, idx) in data.contracts" class="d-flex align-items-center" :key="data.invoice_id+'_'+idx+'_'+contract.contract_id">
                        <router-link :to="{ name: 'ContractFormv2', params: { contract_id: contract.contract_id }}">
                            {{ contract.contract_num }}
                        </router-link>
                    </span>
                </template>
			</CustomTable>
        </div>

        <b-modal ref="modalPayment" hide-footer size="xl">
            <template v-slot:modal-title>
                    {{ $t("payment.add_payment") }}
            </template>

            <AddPayment ref="addPayment" :invoice_ids="invoice_ids" :invoice_nums="invoice_nums" :processing.sync="processing" :ready.sync="ready" :submit_payment.sync="submit_payment"></AddPayment>

            <div class="d-none col-8 m-auto">
                <b-button v-if="ready" block pill variant="primary" @click.prevent="onSubmit"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.ajouter") }}</b-button>
            </div>
        </b-modal>

        <b-modal ref="modalEditPayment" hide-footer>
            <template v-slot:modal-title>
                    {{ $t("payment.edit_payment") }}
            </template>

            <EditPayment ref="editPayment" :payment="payment" :processing.sync="processing" :ready.sync="ready"></EditPayment>

            <div class="col-8 m-auto">
                <b-button v-if="ready" block pill variant="primary" @click.prevent="onSubmitEdit"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.modifier") }}</b-button>
            </div>
        </b-modal>

        <b-modal size="xl" ref="modelPreview" hide-footer @hidden="base64 = null">
            <template v-slot:modal-title>
                {{ $t("action.previsualiser") }}
            </template>

            <iframe v-if="base64 != null" :src="'data:application/pdf;base64,' + base64" height="1000px" width="100%" style="position:relative"></iframe>
            <div v-else>
                <LoadingSpinner />
            </div>
        </b-modal>

        <ModalConfirm
            ref="confirm_cancel_payment"
            id="confirm_cancel_payment_c"
            :icon="['far', 'check']"
            :icon_accept="['fal', 'level-down']"
            :text_title="$t('modal.invoice.validation_cancel_payment')"
            :text_question="$t('modal.invoice.text_validation_cancel_payment')"
            :text_button_ok="$t('modal.general.ok')"
            :text_button_cancel="$t('modal.general.annuler')"
            :callback_validation="checkCancelPayment"
            :callback_cancel="cancelSwitchCancelPaymentModal"
            :processing="processing_cancel_payment"
        />

    </div>
</template>


<script type="text/javascript">
    import Vue from 'vue'
    import { EventBus } from 'EventBus'

    import TableAction from "@/mixins/TableAction.js"
    import Invoice from "@/mixins/Invoice.js"
    import Navigation from "@/mixins/Navigation.js"
    import Payment from "@/mixins/Payment.js"
    import Config from "@/mixins/Config.js"


    export default {
        name: "list",
        mixins: [Config, TableAction, Invoice, Navigation, Payment],
        props: [],
        data () {
            return {
                payments: null,
                table_busy: true,
                modal_loaded: false,
                ready: false,
                base64: null,
                processing: false,
                config_table_hrefs: {
                    'tiers.tiers_rs': {
                        routeUniqueName: 'tiersFiche',
                        routeName: 'tiersFiche',
                        params: {
                            tiers_id: 'tiers.tiers_id'
                        }
                    }
                },
                invoice_ids: null,
                invoice_nums: null,
                processing_cancel_payment: false,
                cancel_params: null,
                rawColumnNames: ['tiers.tiers_rs'],
				extern_slot_columns: ['payment_exported', 'check_deposit_formatted', 'formatted_invoices', 'contracts'],
                events_tab: {
                    'TableAction::goToUnpaidPayment': this.check_unpaid_payment,
                    'TableAction::goToCancelPayment': this.confirmCheckCancelPayment,
                    'TableAction::goToExportPayment': this.export_payment,
                    'TableAction::goToEditPayment': this.edit_payment,
                    'TableAction::goToAccountedPayment': (payment_ids) => {
						this.accounted(payment_ids)
					},
				},
				start_date: new Date(new Date().setDate(new Date().getDate() - 7)),
                end_date: new Date(),
                payment: null,
                submit_payment: false
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.loadPayments()
            },
            async loadPayments() {
                this.table_busy = true
                const payments = await this.listPayment(null, this.start_date, this.end_date)
                this.payments = await this.formatPayments(payments)
                this.table_busy = false
            },
            async onSubmit() {
                if(!this.processing)
                {
                    this.processing = true
                    await this.$refs['addPayment'].addPayment()
                    this.loadPayments()
                }
            },
            async onSubmitEdit() {
                const done = await this.$refs['editPayment'].editPayment()
                if(done) {
                    this.$refs["modalEditPayment"].hide()
                    this.loadPayments()
                }
            },
            async export_payment(params) {
                let payment_ids = params.payment_ids
                await this.loadExportPayment(payment_ids.split(","))
                EventBus.$emit('TableAction::stopSpin')
            },
			print(params) {
				this.printCheckDeposit(params)
			},
            accounted(invoice_ids) {
				this.accounted_payments(invoice_ids)
				.then(() => {
					this.table_busy = true
					this.loadPayments()
				})
			},
            edit_payment(params) {
                this.payment = params
                this.payment.payment_date = new Date(this.payment.payment_date)
                this.$refs['modalEditPayment'].show()
            },
            async quickPreview(invoice_id) {
                this.$refs.modelPreview.show()
                let retour = await this.pdfInvoice([invoice_id], "", true)
                if(retour) {
                    this.base64 = retour
                }
            },
            async display_today_payments() {
                this.start_date = new Date()
                await this.loadPayments()
            },
            unselectAll() {
        		this.$refs.table_payment.unselectAll()
        	},
            confirmCheckCancelPayment(cancel_params) {
                this.cancel_params = cancel_params
                return this.$refs.confirm_cancel_payment.openModal()
            },
            cancelSwitchCancelPaymentModal() {
				this.$refs.confirm_cancel_payment.closeModal()
			},
            async checkCancelPayment(){
                this.processing_cancel_payment = true
                const res = await this.check_cancel_payment(this.cancel_params)
                this.processing_cancel_payment = false
                this.$refs.confirm_cancel_payment.closeModal()
                this.unselectAll()

                // Si pas de retour, c'est qu'il y a une erreur, pas besoin de refresh la table
                if (!res || !res.retour) return

                this.table_busy_payments = true
                await this.init_component()
            },
        },
        watch: {
            submit_payment() {
                this.onSubmit()
            }
        },
        components: {
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable') ,
            AddPayment: () => import('@/components/Payment/AddPayment'),
            EditPayment: () => import('@/components/Payment/EditPayment'),
            DateRangeV2: () => import('GroomyRoot/components/Inputs/DateRangeV2'),
            ModalConfirm: () => import('@/components/Modals/ModalConfirm'),
        }
    }
</script>
